import { useState } from "react";
import HeaderHooks from "./HeaderHooks";
import "./Header.css";
import Dailyrate from "./DailyRate";
import Menu from "./Menu";
import Search from "./Search";
import Videorequest from "./VideoRequest";
import { Link } from "react-router-dom";
import MobileHeader from "./MobileHeader";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { LazyLoadImage } from "react-lazy-load-image-component";

const Header = ({ count: propCount, cart: propCart }) => {
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;
  const {
    logo,
    header_icon2,
    header_icon4,
    header_icon6,
    header_icon5,
    header_icon7,
    header_icon8,
    header_branches,
    logout,
    handleLogout,
    handleLogin,
    showDailyRate,
    openDailyRate,
    closeDailyRate,
    showSearchBar,
    openSearch,
    closeSearch,
    showVideoCall,
    openVideoCall,
    closeVideoCall,
    handleLoginForNonUser,
    block,
    dailyDataDescription,
  } = HeaderHooks(propCount, propCart);

  const [dropdownMenu, openDropdownMenu] = useState(false);
  const menuData = useSelector((state) => state.abData.category);

  const openDropdown = () => {
    openDropdownMenu(!dropdownMenu);
  };

  const headerContainer = () => {
    return (
      <div className="header_container flt">
        <Helmet>
          <meta name="description" content={dailyDataDescription?.info} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div className="header_links header_links_left mobile_hide">
          <ul>
            <li>
              <Link to="/return-policy">
                <LazyLoadImage
                  src={header_icon2}
                  height="30px"
                  width="30px"
                  alt="image"
                />
                <br />
                Shipping all over India
              </Link>
            </li>

            <li>
              <a onClick={openDailyRate}>
                <LazyLoadImage
                  src={header_icon4}
                  height="30px"
                  width="30px"
                  alt="image"
                />
                <br />
                Daily Rates
              </a>
            </li>
            <li>
              <Link
                to="/contact"
                state={{ scrollToBottom: true }} // Pass the state
              >
                <LazyLoadImage
                  src={header_branches}
                  height="30px"
                  width="30px"
                  alt="image"
                />
                <br /> Branches
              </Link>
            </li>

            <li>
              <a onClick={openSearch}>
                <LazyLoadImage
                  src={header_icon6}
                  height="30px"
                  width="30px"
                  alt="image"
                />
                <br />
                Search
              </a>
            </li>
          </ul>
        </div>

        <div className="header_logo">
          <Link to="/">
            <LazyLoadImage src={logo} alt="logo" height="79px" width="350px" />
          </Link>
        </div>
        <div className="header_links header_links_right mobile_hide">
          <ul>
            <li>
              <a className="dropdown_btn" onClick={openDropdown}>
                <LazyLoadImage
                  src={header_icon5}
                  height="30px"
                  width="30px"
                  alt="image"
                />
                <br />
                My Account
              </a>
              {dropdownMenu && (
                <ul className="dropdown_menu">
                  {isUser ? (
                    <li>
                      <Link to="/my-details">
                        <LazyLoadImage
                          src={header_icon5}
                          height="30px"
                          width="30px"
                          alt="image"
                        />
                        My Account
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link to="/login" onClick={() => handleLoginForNonUser()}>
                        <LazyLoadImage
                          src={header_icon5}
                          height="30px"
                          width="30px"
                          alt="image"
                        />
                        My Account
                      </Link>
                    </li>
                  )}

                  {isUser ? (
                    <li>
                      {" "}
                      <a onClick={handleLogout}>
                        <LazyLoadImage
                          src={logout}
                          height="20px"
                          width="20px"
                          alt="image"
                        />
                        Logout
                      </a>
                    </li>
                  ) : (
                    <li>
                      {" "}
                      <a onClick={handleLogin}>
                        <LazyLoadImage
                          src={logout}
                          height="20px"
                          width="20px"
                          alt="image"
                        />
                        Login
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </li>

            {isUser ? (
              propCount > 0 ? (
                <li>
                  <Link to="/wishlist">
                    <LazyLoadImage
                      src={header_icon7}
                      height="30px"
                      width="30px"
                      alt="image"
                    />
                    <br /> Wishlist ({propCount})
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/wishlist">
                    <LazyLoadImage
                      src={header_icon7}
                      height="30px"
                      width="30px"
                      alt="image"
                    />
                    <br /> Wishlist
                  </Link>
                </li>
              )
            ) : (
              <li>
                <a onClick={handleLoginForNonUser}>
                  {" "}
                  <LazyLoadImage
                    src={header_icon7}
                    height="30px"
                    width="30px"
                    alt="image"
                  />{" "}
                  <br /> Wishlist
                </a>
              </li>
            )}

            {isUser ? (
              propCart > 0 ? (
                <li>
                  <Link to="/cart">
                    <LazyLoadImage
                      src={header_icon8}
                      height="30px"
                      width="30px"
                      alt="image"
                    />
                    <br />
                    Cart <span>{propCart}</span>
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/cart">
                    <LazyLoadImage
                      src={header_icon8}
                      height="30px"
                      width="30px"
                      alt="image"
                    />
                    <br />
                    Cart
                  </Link>
                </li>
              )
            ) : (
              <li>
                <a onClick={handleLoginForNonUser}>
                  {" "}
                  <LazyLoadImage
                    src={header_icon8}
                    height="30px"
                    width="30px"
                    alt="image"
                  />{" "}
                  <br /> Cart{" "}
                </a>
              </li>
            )}
          </ul>
          <button
            className={block === 1 ? "blockstate" : ""}
            onClick={openVideoCall}
          >
            Request for video call
          </button>
        </div>
      </div>
    );
  };
  const renderSearch = () => {
    return <Search closeSearch={() => closeSearch()} />;
  };
  const renderDailyRates = () => {
    return <Dailyrate closeDailyRate={() => closeDailyRate()} />;
  };
  const renderVideoRequest = () => {
    return <Videorequest closeVideoCall={() => closeVideoCall()} />;
  };
  const renderMobileHeader = () => {
    return <MobileHeader />;
  };
  const renderMenuScreen = () => {
    return <Menu count={propCount} cart={propCart} />;
  };
  return (
    <div className="main_container flt">
      <div className="site_nav flt">
        {headerContainer()}
        {menuData && renderMenuScreen()}
        {showSearchBar && renderSearch()}
        {showDailyRate && renderDailyRates()}
        {showVideoCall && renderVideoRequest()}
        {renderMobileHeader()}
      </div>
    </div>
  );
};
export default Header;
