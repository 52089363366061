import HeaderHooks from "./HeaderHooks";
import { Link } from "react-router-dom";
import Dailyrate from "./DailyRate";
import Search from "./Search";
import Videorequest from "./VideoRequest";
import "./Header.css";

import { LazyLoadImage } from "react-lazy-load-image-component";

const MobileHeader = () => {
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;
  const {
    header_branches,
    header_icon4,
    header_icon6,
    header_icon5,
    showDailyRate,
    openDailyRate,
    closeDailyRate,
    showSearchBar,
    openSearch,
    closeSearch,
    showVideoCall,
    openVideoCall,
    closeVideoCall,
    block,
    handleLoginForNonUser,
  } = HeaderHooks();

  const renderSearch = () => {
    return <Search closeSearch={() => closeSearch()} />;
  };
  const renderDailyRates = () => {
    return <Dailyrate closeDailyRate={() => closeDailyRate()} />;
  };
  const renderVideoRequest = () => {
    return <Videorequest closeVideoCall={() => closeVideoCall()} />;
  };

  return (
    <div className="mobile_header hide_desktop flt">
      <ul className="flt">
        <li>
          <a onClick={openSearch}>
            <LazyLoadImage
              src={header_icon6}
              height="30px"
              width="30px"
              alt="image"
            />
            <br />
            Search
          </a>
        </li>
        <li>
          <a onClick={openDailyRate}>
            <LazyLoadImage
              src={header_icon4}
              height="30px"
              width="30px"
              alt="image"
            />
            <br />
            Daily Rates
          </a>
        </li>
        <li>
          <Link
            to="/contact"
            state={{ scrollToBottom: true }} // Pass the state
          >
            <LazyLoadImage
              src={header_branches}
              height="30px"
              width="30px"
              alt="image"
            />
            <br /> Branches
          </Link>
        </li>
        {isUser ? (
          <li>
            <Link to="/my-details">
              <LazyLoadImage
                src={header_icon5}
                height="30px"
                width="30px"
                alt="image"
              />
              <br />
              My Account
            </Link>
          </li>
        ) : (
          <li>
            <Link to="/login" onClick={() => handleLoginForNonUser()}>
              <LazyLoadImage
                src={header_icon5}
                height="30px"
                width="30px"
                alt="image"
              />
              <br />
              My Account
            </Link>
          </li>
        )}
      </ul>
      <button
        className={
          block === 1
            ? "blockstate mobile_videocall flt"
            : "mobile_videocall flt"
        }
        onClick={openVideoCall}
      >
        Request for Video call
      </button>

      {showSearchBar && renderSearch()}
      {showDailyRate && renderDailyRates()}
      {showVideoCall && renderVideoRequest()}
    </div>
  );
};
export default MobileHeader;
