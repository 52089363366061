import React from "react";
import { useState, useEffect } from "react";
import { HeaderService } from "../../../services/HeaderService";
import daily_rate from "../../../assets/images/daily-rate.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import daily_rate_close from "../../../assets/images/daily-rate-close.png";

import "./Header.css";
import { Helmet } from "react-helmet";
import DailyRateHooks from "./DailyRateHooks";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Dailyrate = (props) => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const { detailsGold } = DailyRateHooks();
  useEffect(() => {
    HeaderService.getDailyRate()
      .then((data) => {
        setData(data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, []);

  let dailyDataDescription = {
    info: `Find the Gold rate in India today. Latest gold rates on Abharan`,
  };
  return (
    /* <!-- Daily Rate Modal --> */

    <div className="modalPop" id="dailyRate">
      <div className="modal-dialog modal-sm">
        <Helmet>
          <meta name="description" content={dailyDataDescription?.info} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div className="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              <LazyLoadImage src={daily_rate} /> Daily Rates
            </h4>
            <button
              type="button"
              className="close"
              onClick={props?.closeDailyRate}
            >
              <LazyLoadImage src={daily_rate_close} />
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Metal Type</th>
                    <th>Per Gram</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="dailyrate_1st">
                    <td>Gold 24 KT</td>
                    <td>
                      RS.
                      {data && data?.data?.gold24}
                    </td>
                  </tr>
                  <tr>
                    <td>Gold 22 KT</td>
                    <td>
                      RS.
                      {data && data?.data?.gold22}
                    </td>
                  </tr>
                  <tr>
                    <td>Gold 18 KT</td>
                    <td>
                      RS.
                      {data && data?.data?.gold18}
                    </td>
                  </tr>
                  <tr>
                    <td>Gold 14 KT</td>
                    <td>
                      RS.
                      {data && data?.data?.gold14}
                    </td>
                  </tr>
                  <tr>
                    <td>Silver</td>
                    <td>
                      RS.
                      {data && data?.data?.silver}
                    </td>
                  </tr>
                  <tr>
                    {/* <!-- <td colspan="2"><b>*Goa rates will be 0.5% more than rates shown here.</b></td> --> */}
                  </tr>
                </tbody>
              </table>
              <div className="details">
                {/* <Link to="/gold-rate-today"> */}
                {window?.location?.pathname !== "/gold-rate-today" ? (
                  <button className="details_btn" onClick={() => detailsGold()}>
                    Details
                  </button>
                ) : (
                  <button
                    className="details_disable_btn"
                    onClick={() => props?.closeDailyRate}
                  >
                    Details
                  </button>
                )}

                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dailyrate;
